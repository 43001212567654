import { useUser } from '@insight2profit/drive-app';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import {
    GridRowModes,
    GridRowModesModel,
    GridRowsProp,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
} from '@mui/x-data-grid-premium';
import { GridToolbarLayoutsButton } from '@price-for-profit/data-grid';
import { random } from 'lodash';
import { IViewCustomerPrices, IViewTemporalCustomerPricesPage } from 'shared/types';
import { ProductPricesToggleCurrency } from '../../productPrices';

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (newModel: (oldModel: GridRowModesModel) => GridRowModesModel) => void;
    customerPricesRow: IViewCustomerPrices | IViewTemporalCustomerPricesPage;
}

export function CustomerPricesExceptionsCustomToolbar({
    setRows,
    setRowModesModel,
    customerPricesRow,
}: EditToolbarProps) {
    const {
        businessLine,
        soldToId,
        shipToId,
        materialId,
        application,
        orgRegion,
        revisedPrice,
        documentCurrency,
        recommendedPrice,
        recommendedPriceTimesPerQuantity,
        uom,
        regionalTarget,
        regionalTargetTimesPerQuantity,
        updatedFloorPrice,
        globalTarget,
        currentPrice,
        perQuantity,
        SAPDocumentCurrency,
    } = customerPricesRow;

    const user = useUser();

    const handleClick = () => {
        const id = random(999999999);
        const today = new Date();
        const nextYear = new Date();
        nextYear.setFullYear(today.getFullYear() + 1);
        setRows(oldRows => [
            ...oldRows,
            {
                id,
                massActionId: 0,
                exceptionType: 'Sales Order',
                salesOrder: '',
                endCustomer: '',
                businessLine,
                soldToId,
                shipToId,
                materialId,
                application,
                orgRegion,
                revisedPrice,
                documentCurrency,
                uom,
                submitPrice: false,
                status: 'Needs Review',
                approver: null,
                modifiedBy: user?.displayName,
                effectiveStart: new Date(),
                currentPrice,
                perQuantity,
                recommendedPrice,
                recommendedPriceTimesPerQuantity,
                regionalTarget,
                regionalTargetTimesPerQuantity,
                globalTarget,
                updatedFloorPrice,
                deleted: false,
                isNew: true,
                SAPDocumentCurrency,
            },
        ]);
        setRowModesModel(oldModel => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'salesOrder' },
        }));
    };

    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <ProductPricesToggleCurrency label={'Document Currency'} />
            <GridToolbarLayoutsButton localStorageName='nouryon-pmt-customer-exceptions' />
            <Button color='primary' startIcon={<AddIcon />} onClick={handleClick}>
                Add record
            </Button>
        </GridToolbarContainer>
    );
}
