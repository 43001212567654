import { useService } from '@insight2profit/drive-app';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { QUERY_KEYS } from 'shared/constants';
import { PermittedRowLevels } from 'shared/services';
import { DataAccessPaginatedResponse } from '@price-for-profit/micro-services';
import { IViewTemporalCustomerPricesPage } from 'shared/types';
import { ServerSideState } from '@price-for-profit/data-grid';

export type TemporalCustomerPricesValidateSubmitMutationParams = {
    state: ServerSideState;
};

export function useTemporalCustomerPricesForSubmitMutation(customerPricesPermittedRowLevels: PermittedRowLevels) {
    const { enqueueSnackbar } = useSnackbar();
    const { temporalCustomerPricesService } = useService();

    const mutation = useMutation<
        DataAccessPaginatedResponse<IViewTemporalCustomerPricesPage>,
        Error,
        TemporalCustomerPricesValidateSubmitMutationParams
    >({
        mutationKey: [`${QUERY_KEYS.temporalCustomerPricesPage}-submit`],
        mutationFn: async ({ state }) => {
            return await temporalCustomerPricesService.getGridData({
                state,
                customerPricesPermittedRowLevels,
                hasValidityCheck: true,
            });
        },
        onSuccess: () => {
            enqueueSnackbar('Mass action successfully completed', { variant: 'success' });
        },
        onError: error => {
            enqueueSnackbar(error.message, { variant: 'error' });
        },
    });

    return mutation;
}
